<script>
/* eslint-disable */
import DefaultMixin from '../mixin'
import LogLeilao from "components/leiloes/components/dashboard/LogLeilao.vue";

export default {
  name: 'LeilaoHistorico',
  inject: ['page'],
  mixins: [DefaultMixin],
  components: {
    LogLeilao
  },
  data() {
    return {
      leilao: this.page.leilao
    }
  },
  computed: {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
  },
  meta: {
    title: 'Histórico',
    name: 'Historico'
  }
}
</script>

<template>
  <div class="page-leilao-container">
    <div class="ev2-body leilao-dash realizacao historico">
    <log-leilao />
    </div>
  </div>
</template>
